.grid-wrapper {
    height: 100vh;
    /* background-image: url(../images/background.png); */
    background-size: cover;
    font-family: "Oswald", sans-serif;
    /*text-transform: uppercase;*/
    padding: 0;
    margin: 0;
    overflow: auto;
}

.grid-left {
    margin: auto;
}

.grid-container-outer {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
}

.creator-awards-logo img {
    width: 400px;
}
